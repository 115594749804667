import { Stack } from "@mui/material";
import { useState } from "react";
import LabeledTextField from "../text/LabeledTextField";
import ImageSourceGroup from "../file/ImageSourceGroup";
import LabeledDateInput from "../date/LabeledDateInput";
import LabeledCheckBox from "../checkbox/LabeledCheckBox";
import ContentGroupControls from "./ContentGroupControls";

const EventsContentForm = (props) => {
  const [eventsObj, setEventsObj] = useState(props.eventsObj);

  const handleUrlChange = (value) => {
    const newObj = { ...eventsObj };
    newObj.url = value.split(" ");
    setEventsObj(()=>newObj)
  }

  const handleChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...eventsObj };
      newObj[key] = value;
      setEventsObj(() => newObj);
    };
  };

  const handleDateChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...eventsObj };
      newObj[key] = `${value.$M+1}-${value.$D}-${value.$y}`
      setEventsObj(()=>newObj)
    };
  }

  return (
    <Stack spacing={1}>
      <LabeledTextField
        label="Title:"
        value={eventsObj.title}
        setValue={handleChangeFactory("title")}
        autofocus={true}
      />
      <LabeledTextField
        label="Subtitle:"
        value={eventsObj.subtitle}
        setValue={handleChangeFactory("subtitle")}
        multiline
      />
      <LabeledTextField
        label="Dates:"
        value={eventsObj.dates}
        setValue={handleChangeFactory("dates")}
      />
      <LabeledTextField
        label="Location:"
        value={eventsObj.location}
        setValue={handleChangeFactory("location")}
        multiline
      />
      <LabeledTextField
        label="Description:"
        value={eventsObj.description}
        setValue={handleChangeFactory("description")}
        multiline
      />
      <LabeledTextField
        label="Link:"
        value={eventsObj.link}
        setValue={handleChangeFactory("link")}
      />
      <LabeledTextField
        label="Link Text:"
        value={eventsObj.linkText}
        setValue={handleChangeFactory("linkText")}
      />
      <LabeledDateInput
        label="Posted:"
        value={eventsObj.posted}
        default={"Select a Posted Date"}
        onChange={handleDateChangeFactory("posted")}
      />
      <LabeledDateInput
        label="Expires:"
        value={eventsObj.expires}
        default={"Select an Expires Date"}
        onChange={handleDateChangeFactory("expires")}
      />
      <LabeledTextField
        label="Url:"
        value={eventsObj.url.join(" ")}
        setValue={handleUrlChange}
      />
      <ImageSourceGroup
        label="Image Source:"
        value={eventsObj.image}
        setValue={handleChangeFactory("image")}
      />
      <LabeledCheckBox
        label="Archived:"
        checked={eventsObj.archived}
        onChange={handleChangeFactory("archived")}
      />
      <ContentGroupControls
        expandNamedArticle={props.expandNamedArticle}
        onSubmit={props.onSubmit}
        section={"events"}
        contentObj={eventsObj}
      />
    </Stack>
  );
};

export default EventsContentForm;
