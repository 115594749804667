import { Box, Stack } from "@mui/material"
import LabeledTextField from "../text/LabeledTextField"
import FileUploadedImage from "./FileUploadedImage"

const ImageSourceGroup = (props) => {
    return (
        <Stack spacing={1}>
            <LabeledTextField
                label={props.label}
                value={props.value}
                setValue={props.setValue}
            />
            <Stack direction={"row"}>
                <Box width={110}/>
                <FileUploadedImage image={props.value}/>
            </Stack>
        </Stack>
    )
}

export default ImageSourceGroup