import { Stack } from "@mui/material"
import NotFound from "../NotFound";
import NavBarBuffer from "../../components/nav/NavBarBuffer"
import { useParams } from 'react-router-dom'
import GalleryCard from "../../components/display/GalleryCard";

const GalleryItem = (props) => {
    const url = useParams().item;
    const items = props.content.filter(piece=>{
        return piece.url.includes(url)
    })
    return (
        <>
            {items.length === 0 && <NotFound />}
            {items.length >= 0 &&
                <Stack p={5} display={"flex"} alignItems={"center"} spacing={1}>
                    <NavBarBuffer height={120} />
                    {items.map(item=><GalleryCard key={Math.random()} content={item} />)}
                </Stack>
            }
        </>
        
    )
}

export default GalleryItem