import { Stack } from "@mui/material";
import { useState } from "react";
import LabeledTextField from "../text/LabeledTextField";
import ImageSourceGroup from "../file/ImageSourceGroup";
import LabeledDateInput from "../date/LabeledDateInput";
import LabeledCheckBox from "../checkbox/LabeledCheckBox";
import ContentGroupControls from "./ContentGroupControls";

const BlogContentForm = (props) => {
  const [blogObj, setBlogObj] = useState(props.blogObj);

  const handleChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...blogObj };
      newObj[key] = value;
      setBlogObj(() => newObj);
    };
  };

  const handleUrlChange = (value) => {
    const newObj = { ...blogObj };
    newObj.url = value.split(" ");
    setBlogObj(()=>newObj)
  }
  
  const handleDateChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...blogObj };
      newObj[key] = `${value.$M+1}-${value.$D}-${value.$y}`
      setBlogObj(()=>newObj)
    };
  }

  return (
    <Stack spacing={1}>
      <LabeledTextField
        label="Title:"
        value={blogObj.title}
        setValue={handleChangeFactory("title")}
        autofocus={true}
      />
      <LabeledTextField
        label="Subtitle:"
        value={blogObj.subtitle}
        setValue={handleChangeFactory("subtitle")}
      />
      <LabeledTextField
        label="Date:"
        value={blogObj.dates}
        setValue={handleChangeFactory("date")}
      />
      <LabeledTextField
        label="Article:"
        value={blogObj.description}
        setValue={handleChangeFactory("article")}
        multiline
      />
      <LabeledDateInput
        label="Posted:"
        value={blogObj.posted}
        default={"Select a Posted Date"}
        onChange={handleDateChangeFactory("posted")}
      />
      <LabeledDateInput
        label="Expires:"
        value={blogObj.expires}
        default={"Select an Expires Date"}
        onChange={handleDateChangeFactory("expires")}
      />
      <LabeledTextField
        label="Url:"
        value={blogObj.url.join(" ")}
        setValue={handleUrlChange}
      />
      <ImageSourceGroup
        label="Image Source:"
        value={blogObj.image}
        setValue={handleChangeFactory("image")}
      />
      <LabeledCheckBox
        label="Archived:"
        checked={blogObj.archived}
        onChange={handleChangeFactory("archived")}
      />
      <ContentGroupControls
        expandNamedArticle={props.expandNamedArticle}
        onSubmit={props.onSubmit}
        section={"blog"}
        contentObj={blogObj}
      />
    </Stack>
  );
};

export default BlogContentForm;