import { Box, Card, Stack } from "@mui/material";
import FileUploadedImage from "../forms/file/FileUploadedImage";
import StyledTypography from "./StyledTypography";

const AboutCard = (props) => {
  const getCardLayout = () => {
    if (props.inverted) {
      return (
        <Stack direction={"row"} p={1} spacing={1}>
          <FileUploadedImage maxHeight={500} image={props.content.image} />
          <Stack>
            <StyledTypography variant="h3">
              {props.content.title}
            </StyledTypography>
            <StyledTypography color="warning.main">{props.content.text}</StyledTypography>
          </Stack>
        </Stack>
      );
    } else {
      return (
        <Stack direction={"row"} p={1} spacing={1}>
          <Stack>
            <StyledTypography variant="h3">
              {props.content.title}
            </StyledTypography>
            <StyledTypography color="warning.main">{props.content.text}</StyledTypography>
          </Stack>
          <FileUploadedImage maxHeight={500} image={props.content.image} />
        </Stack>
      );
    }
  };
  return (
    <Box width={"fit-content"}>
      <Card>{getCardLayout()}</Card>
    </Box>
  );
};

export default AboutCard;
