import { Box, Stack, Typography } from "@mui/material";
import NavBarBuffer from "../components/nav/NavBarBuffer";
import brokenHeart from "../resources/brokenHeart.png"
function NotFound() {
  return (
    <Stack p={5} display={"flex"} alignItems={"center"} spacing={2}>
      <NavBarBuffer />
      <Typography variant="h5">404: Page Not Found</Typography>
      <Box
          component="img"
          src={brokenHeart}
          style={{
            backgroundImage: `url(${brokenHeart})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            maxWidth: "183px",
            maxHeight: "143px",
          }}
        />
        <Typography variant="h6">Please use the Navigation Menu to return to the main pages.</Typography>
    </Stack>
  );
}

export default NotFound;
