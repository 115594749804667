import { Link } from "@mui/material";
const NewTabLink = (props) => {
  return (
    <Link target="_blank" rel="noopener" href={props.href} color="warning.main">
      {props.text}
    </Link>
  );
};

export default NewTabLink;
