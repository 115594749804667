import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Button,
  Box,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import duck from "../../resources/duck.png";
import StyledTextField from "./text/StyledTextField";
const AdminModal = (props) => {
  const [open, setOpen] = useState(false);
  const [password, setPassword] = useState("");

  const navigate = useNavigate()
  return (
    <>
      <Box
        component="img"
        height={23}
        width={38}
        alt="Admin Login Button"
        src={duck}
        onClick={() => {props.isAdmin ? navigate("/admin") : setOpen(true)}}
        sx={{
          position: "fixed",
          bottom: 50,
          left: "50%",
          zIndex: 2,
          opacity: "15%",
        }}
      />
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setPassword("");
        }}
      >
        <DialogTitle color={"primary"}>Admin Login</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter Admin Password</DialogContentText>
          <StyledTextField
            value={password}
            type="password"
            setValue={setPassword}
            autofocus={true}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpen(false);
              setPassword("");
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              setOpen(false);
              props.submit(password);
              setPassword("");
            }}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminModal;
