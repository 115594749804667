import { Box, Card, Stack } from "@mui/material";
import StyledTypography from "./StyledTypography";
import FileUploadedImage from "../forms/file/FileUploadedImage";

const BlogCard = (props) => {
  return (
    <Box width={"fit-content"}>
      <Card>
        <Stack p={1}>
          <StyledTypography variant={"h2"}>
            {props.content.title}
          </StyledTypography>
          <StyledTypography variant={"h5"} color={"warning.main"}>
            {props.content.subtitle}
          </StyledTypography>
          <StyledTypography variant={"body2"}>
            {props.content.date}
          </StyledTypography>
          <Stack direction={"row"}>
            {props.content.image && (
              <FileUploadedImage maxHeight={700} image={props.content.image} />
            )}
            <StyledTypography>{props.content.article}</StyledTypography>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default BlogCard;
