import { Stack } from "@mui/material";
import BlogCard from "../../components/display/BlogCard";
import NavBarBuffer from "../../components/nav/NavBarBuffer";
import StyledTypography from "../../components/display/StyledTypography";

function Events(props) {
  return (
    <Stack p={5} display={"flex"} alignItems={"center"} spacing={1}>
      <NavBarBuffer height={120}/>
      <StyledTypography color="warning.main" variant={"h2"}>Blog</StyledTypography>
      {props.content.map((item) => (
        <BlogCard key={Math.random()} content={item} />
      ))}
    </Stack>
  );
}

export default Events;