import {
  AppBar,
  Box,
  Typography,
  Stack,
  Paper,
  alpha,
  Button,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";
import useNavSettings from "../../hooks/useNavSettings";
import ButtonArray from "./ButtonArray";
import royalBlueHeartOutline from "../../resources/royalBlueHeartOutline.png";
import { useState } from "react";
const NavBar = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const menuButtonClickEventHandler = (e) => {
    setAnchorEl(e.target);
  };
  const navigate = useNavigate();
  const navPageSettings = useNavSettings("text");

  const getHeart = () => {
    if (props.screenSize === "landscape") return <></>;
    return (
      <Box
        mt={"15px"}
        component="img"
        height={100}
        width={100}
        alt="Royal Blue Heart Outline"
        src={royalBlueHeartOutline}
        sx={{
          height: { tablet: 75, portrait: 50 },
          width: { tablet: 75, portrait: 50 },
        }}
      />
    );
  };

  const getOffset = () => {
    if (props.screenSize === "landscape") return <></>;
    return (
      <Box
        mt={"15px"}
        height={100}
        width={100}
        sx={{
          height: { tablet: 75, portrait: 50 },
          width: { tablet: 75, portrait: 50 },
        }}
      />
    );
  };

  const getButtons = () => {
    if (props.screenSize !== "laptop" && props.screenSize !== "desktop")
      return <></>;
    return (
      <ButtonArray buttonSettings={navPageSettings} width={"0px"} spacing={0} />
    );
  };

  const getMenu = () => {
    if (props.screenSize !== "landscape") return <></>;
    return (
      <>
        <IconButton
          onClick={menuButtonClickEventHandler}
          sx={{ position: "fixed", top: 5, right: 20 }}
        >
          <MenuIcon />
        </IconButton>
        <Menu open={open} onClose={closeMenu} anchorEl={anchorEl}>
          {navPageSettings.map((set) => (
            <MenuItem
              key={set.page}
              onClick={() => {
                closeMenu();
                set.onClick();
              }}
            >
              <Typography color={set.color}>{set.page}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  return (
    <AppBar
      className="app-bar"
      position="absolute"
      sx={{ maxWidth: "100vw", background: "transparent", boxShadow: "none" }}
    >
      <Stack direction="column" alignItems={"center"}>
        <Stack direction={"row"} spacing={1}>
          {getHeart()}
          <Stack direction={"column"} alignItems={"center"}>
            <Box>
              <Paper sx={{ backgroundColor: alpha("#2b7c85", 0.4) }}>
                <Button
                  onClick={() => navigate("/")}
                  sx={{ textTransform: "none" }}
                >
                  <Typography
                    p={1}
                    variant="h3"
                    color="text.primary"
                    sx={{ typography: { landscape: "h3", portrait: "h5" } }}
                  >
                    Nature's Dreamscapes
                  </Typography>
                </Button>
              </Paper>
            </Box>
            <Box>
              <Paper sx={{ backgroundColor: alpha("#2b7c85", 0.4) }}>
                <Typography p={1} sx={{ typography: { xs: "body2" } }}>
                  artwork by Pamela Lewis
                </Typography>
              </Paper>
            </Box>
            {getButtons()}
            {getMenu()}
          </Stack>
          {getOffset()}
        </Stack>
      </Stack>
    </AppBar>
  );
};

export default NavBar;
