import { Box, Stack } from "@mui/material";
import { LABEL_TEXT_WIDTH } from "../../../consts/layoutConsts";
import StyledTypography from "../../display/StyledTypography";
import CheckBox from "./CheckBox";

const LabeledCheckBox = (props) => {
  return (
    <Stack direction={"row"} alignItems={"center"}>
      <Box width={LABEL_TEXT_WIDTH}>
        <StyledTypography>{props.label}</StyledTypography>
      </Box>
        <CheckBox checked={props.checked} onChange={props.onChange}/>
    </Stack>
  );
};

export default LabeledCheckBox;