import "./App.css";
import { Routes, Route } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useWindowSize from "./hooks/useWindowSize";
import NavBar from "./components/nav/NavBar";
import Home from "./pages/Home";
import About from "./pages/About";
import Admin from "./pages/Admin";
import Contact from "./pages/Contact";
import Events from "./pages/Events/Events";
import Gallery from "./pages/Gallery/Gallery";
import GalleryItem from "./pages/Gallery/GalleryItem";
import Blog from "./pages/Blog/Blog";
import BlogItem from "./pages/Blog/BlogItem";
import EventsItem from "./pages/Events/EventsItem";
import NotFound from "./pages/NotFound";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  const navigate = useNavigate();
  const windowSizeValues = useWindowSize();

  const emptyContent = {
    about: [],
    gallery: [],
    collections: [],
    events: [],
    blog: [],
    contact: "",
  };

  const [ratio, setRatio] = useState(
    windowSizeValues[0] / windowSizeValues[1] || 0
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const [content, setContent] = useState(emptyContent);
  const [isLoaded, setIsLoaded] = useState(false);

  

  useEffect(() => {
    setRatio(windowSizeValues[0] / windowSizeValues[1] || 0);
  }, [windowSizeValues]);

  useEffect(() => {
    axios
      .get(`${generateUrl("production")}/content`)
      .then((res) => {
        setContent(res.data);
        setIsLoaded(true);
      })
      .catch((err) => {
        setIsLoaded(true);
      });
  }, []);

  const theme = useTheme();
  let screenSize = "portrait";
  if (useMediaQuery(theme.breakpoints.up("laptop"))) {
    screenSize = "laptop";
  } else if (ratio >= 0.7 && ratio <= 1.2) {
    screenSize = "tablet";
  } else if (ratio > 1.2 && ratio <= 2) {
    screenSize = "desktop";
  } else if (ratio > 2) {
    screenSize = "landscape";
  }

  const setContentAndUpdateLocalState = (newContentFunc) => {
    const newContent = newContentFunc();
    setContent(newContent);
  };

  const currentENV = "production"

  const generateUrl = (env) => {
    return env === "production" ? "https://natures-backend.vercel.app" : "http://localhost:8025"
  }

  const onNewPassword = (password) => {
    axios
      .post(
        `${generateUrl(currentENV)}/admin/password`,
        { password },
        { headers: { auth: sessionStorage.getItem("authToken") } }
      )
      .then(() => {
        window.alert("Password Changed Successfully");
      })
      .catch((err) => {
        window.alert("There was an issue with changing the password");
        setIsAdmin(false);
        navigate("/home");
      });
  };

  const passwordSubmit = (password) => {
    axios
      .post(`${generateUrl(currentENV)}/login`, { password })
      .then((res) => {
        sessionStorage.setItem("authToken", res.data);
        setIsAdmin(true);
        navigate("/admin");
      })
      .catch(() => {
        sessionStorage.setItem("authToken", "");
        setIsAdmin(false);
        navigate("/home");
      });
  };

  const onNewAuthTimeout = (timeout) => {
    axios
      .post(
        `${generateUrl(currentENV)}/admin/timeout`,
        { timeout },
        { headers: { auth: sessionStorage.getItem("authToken") } }
      )
      .then(() => {
        window.alert("New Timeout Set");
      })
      .catch((err) => {
        window.alert("There was an issue with setting the timeout");
        setIsAdmin(false);
        navigate("/home");
      });
  };

  const publishButtonEventHandler = () => {
    const nonTempContent = {...content}
    nonTempContent.about = nonTempContent.about.filter(item=>!item.temp);
    nonTempContent.collections = nonTempContent.collections.filter(item=>!item.temp)
    nonTempContent.gallery = nonTempContent.gallery.filter(item=>!item.temp)
    nonTempContent.events = nonTempContent.events.filter(item=>!item.temp)
    nonTempContent.blog = nonTempContent.blog.filter(item=>!item.temp)
    axios
      .post(`${generateUrl(currentENV)}/admin/content`, content, {
        headers: { auth: sessionStorage.getItem("authToken") },
      })
      .then(() => {
        window.alert("Published Successfully");
      })
      .catch(() => {
        window.alert("There was an issue with publishing");
        setIsAdmin(false);
        navigate("/home");
      });
    setContent(()=>nonTempContent)
  };

  return (
    <>
      {isLoaded && (
        <>
          <NavBar screenSize={screenSize} />
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  isAdmin={isAdmin}
                  passwordSubmit={passwordSubmit}
                  screenSize={screenSize}
                  setIsAdmin={setIsAdmin}
                />
              }
            />
            <Route
              path="/home"
              element={
                <Home
                  isAdmin={isAdmin}
                  passwordSubmit={passwordSubmit}
                  screenSize={screenSize}
                  setIsAdmin={setIsAdmin}
                />
              }
            />
            <Route
              path="/about"
              element={<About content={content.about}/>}
            />
            <Route
              path="/contact"
              element={
                <Contact contact={content.contact}/>
              }
            />
            <Route
              path="/events"
              element={<Events content={content.events}/>}
            />
            <Route
              path="/events/:item"
              element={
                <EventsItem content={content.events}/>
              }
            />
            <Route
              path="/gallery"
              element={
                <Gallery
                  content={content.gallery}
                  collections={content.collections}
                />
              }
            />
            <Route
              path="/gallery/:item"
              element={
                <GalleryItem content={content.gallery}/>
              }
            />
            <Route
              path="/blog"
              element={<Blog content={content.blog}/>}
            />
            <Route
              path="/blog/:item"
              element={<BlogItem content={content.blog}/>}
            />
            {isAdmin && (
              <Route
                path="/admin"
                element={
                  <Admin
                    content={content}
                    setContent={setContentAndUpdateLocalState}
                    onNewAuthTimeout={onNewAuthTimeout}
                    onNewPassword={onNewPassword}
                    onPublish={publishButtonEventHandler}
                  />
                }
              />
            )}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </>
      )}
    </>
  );
}

export default App;
