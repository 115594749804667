import { Box } from "@mui/material";

const FileUploadedImage = (props) => {

  const pointer = props.onClick ? "pointer" : "auto"
  return (
    <>
      {props.image && (
        <Box
          component="img"
          maxHeight={props.maxHeight || 100}
          alt={`Uploaded file named ${props.name}`}
          src={props.image}
          onClick={props.onClick}
          sx={{cursor:pointer}}
        />
      )}
    </>
  );
};

export default FileUploadedImage;
