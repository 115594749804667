import { Box, Stack } from "@mui/material";
import StyledTypography from "./StyledTypography";

const LabeledTypography = (props) => {
  return (
    <Stack direction={"row"}>
      <Box width={props.width || 100}>
        <StyledTypography color={props.color}>{props.label}</StyledTypography>
      </Box>
      <StyledTypography>{props.children}</StyledTypography>
    </Stack>
  );
};

export default LabeledTypography;
