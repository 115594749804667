import { Box, Stack } from "@mui/material";
import { LABEL_TEXT_WIDTH } from "../../../consts/layoutConsts";
import StyledTypography from "../../display/StyledTypography";
import DateInput from "./DateInput";

const LabeledDateInput = (props) => {
  return (
    <Stack direction={"row"} alignItems={"baseline"}>
      <Box width={LABEL_TEXT_WIDTH}>
        <StyledTypography>{props.label}</StyledTypography>
      </Box>
      <DateInput value={props.value} default={props.default} onChange={props.onChange}/>
    </Stack>
  );
};

export default LabeledDateInput;
