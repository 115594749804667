import { Stack } from "@mui/material"
import NotFound from "../NotFound";
import NavBarBuffer from "../../components/nav/NavBarBuffer"
import { useParams } from 'react-router-dom'
import BlogCard from "../../components/display/BlogCard";
const BlogItem = (props) => {
    const url = useParams().item;
    const items = props.content.filter(piece=>piece.url.includes(url))
    return (
        <>
            {items.length === 0 && <NotFound />}
            {items.length >= 0 &&
                <Stack p={5} display={"flex"} alignItems={"center"} spacing={1}>
                    <NavBarBuffer height={120} />
                    {items.map(item=><BlogCard key={Math.random()} content={item} />)}
                </Stack>
            }
        </>
        
    )
}

export default BlogItem