import { Stack } from "@mui/material";
import EventsCard from "../../components/display/EventsCard";
import NavBarBuffer from "../../components/nav/NavBarBuffer";
import StyledTypography from "../../components/display/StyledTypography";
import NewBadge from "../../components/display/NewBadge";

function Events(props) {
  return (
    <Stack p={5} display={"flex"} alignItems={"center"} spacing={1}>
      <NavBarBuffer height={120} />
      <StyledTypography color="warning.main" variant={"h2"}>
        Upcomming Events!
      </StyledTypography>
      {props.content
        .filter((item) => !item.archived && (!item.expires || Date.now() < new Date(item.expires).getTime()))
        .map((item) => (
          <Stack key={Math.random()}>
            <NewBadge postedDate={item.posted} />
            <EventsCard key={Math.random()} content={item} />
          </Stack>
        ))}
    </Stack>
  );
}

export default Events;
