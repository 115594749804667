import { useNavigate } from "react-router";

const useNavSettings = (buttonType = "text", color = "primary.main") => {
    const navigate = useNavigate();
    return [
    {
      page: "Gallery",
      type: buttonType,
      color: "warning.main",
      onClick: () => {
        navigate("/gallery");
      },
    },
    {
      page: "About",
      type: buttonType,
      color: color,
      onClick: () => {
        navigate("/about");
      },
    },
    {
      page: "Events",
      type: buttonType,
      color: color,
      onClick: () => {
        navigate("/events");
      },
    },
    {
      page: "Blog",
      type: buttonType,
      color: color,
      onClick: () => {
        navigate("/blog");
      },
    },
    {
      page: "Contact",
      type: buttonType,
      color: color,
      onClick: () => {
        navigate("/contact");
      },
    },
  ];
}

export default useNavSettings