import { Stack, Typography, IconButton, Box, Button } from "@mui/material";
import LibraryAddIcon from "@mui/icons-material/LibraryAdd";
import FormAccordion from "../FormAccordion";
import { capitalizeFirstLetter } from "../../../utils/stringUtils";
import GalleryContentForm from "./GalleryContentForm";
import EventsContentForm from "./EventsContentForm";
import AboutContentForm from "./AboutContentForm";
import BlogContentForm from "./BlogContentForm";
import CollectionsContentForm from "./CollectionsContentForm";

const ContentGroup = (props) => {

    const generateNewObj = () => {
        const newObj = {}
        props.schema.entries.forEach(entry=>{
            newObj[entry.label] = entry.default
        })
        newObj["temp"] = true;
        return newObj;
    }

  const addNewItem = () => {
    const contentObjs = [...props.content];
    const newObj = generateNewObj()
    contentObjs.unshift(newObj);
    contentObjs.forEach((obj,i)=>obj.index=i)
    props.onSubmit(props.schema.section, contentObjs);
    props.expandNamedArticle(newObj.title);
  };

  return (
    <Stack mt={1}>
      <Stack direction={"row"} alignItems={"center"}>
        <Box width="100%">
          <Typography variant="h6">{capitalizeFirstLetter(props.schema.section)}</Typography>
        </Box>
        <IconButton onClick={addNewItem}>
          <LibraryAddIcon />
        </IconButton>
      </Stack>
      {props.content.length > 0 &&
        props.content.map((contentObj) => (
          <FormAccordion
            key={Math.random()}
            expanded={props.expanded}
            handleChange={props.handleChange}
            title={contentObj.title}
          >
            {props.schema.section === "gallery" && 
            <GalleryContentForm
              expandNamedArticle={props.expandNamedArticle}
              galleryObj={contentObj}
              onSubmit={props.onSubmit}
            />}
            {props.schema.section === "collections" && 
            <CollectionsContentForm
              expandNamedArticle={props.expandNamedArticle}
              collectionsObj={contentObj}
              onSubmit={props.onSubmit}
            />}
            {props.schema.section === "events" && 
            <EventsContentForm
              expandNamedArticle={props.expandNamedArticle}
              eventsObj={contentObj}
              onSubmit={props.onSubmit}
            />}
            {props.schema.section === "about" && 
            <AboutContentForm
              expandNamedArticle={props.expandNamedArticle}
              aboutObj={contentObj}
              onSubmit={props.onSubmit}
            />}
            {props.schema.section === "blog" && 
            <BlogContentForm
              expandNamedArticle={props.expandNamedArticle}
              blogObj={contentObj}
              onSubmit={props.onSubmit}
            />}
          </FormAccordion>
        ))}
      {props.content.length === 0 && <Box>
          <Button onClick={addNewItem} sx={{ textTransform: "none" }}>
            <Typography color="white">No Items</Typography>
          </Button>
        </Box>}
    </Stack>
  );
};

export default ContentGroup;
