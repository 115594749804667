import { Box, Stack } from "@mui/material";
import StyledTextField from "./StyledTextField";
import { LABEL_TEXT_WIDTH } from "../../../consts/layoutConsts";
import StyledTypography from "../../display/StyledTypography";

const LabeledTextField = (props) => {
  return (
    <Stack direction={"row"} alignItems={"baseline"}>
      <Box width={LABEL_TEXT_WIDTH}>
        <StyledTypography>{props.label}</StyledTypography>
      </Box>
      <StyledTextField
        value={props.value}
        setValue={props.setValue}
        multiline={props.multiline}
        type={props.type}
        autofocus={props.autofocus}
      />
    </Stack>
  );
};

export default LabeledTextField;
