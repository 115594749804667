import { Stack, Box } from "@mui/material";
import useNavSettings from "../hooks/useNavSettings";
import reflections from "../resources/reflections.jpg";
import ButtonArray from "../components/nav/ButtonArray";
import AdminModal from "../components/forms/AdminModal";
import StyledTypography from "../components/display/StyledTypography";
import NavBarBuffer from "../components/nav/NavBarBuffer";
const Home = (props) => {
  const navPageSettings = useNavSettings("outlined", "#ffffff");

  const getButtons = () => {
    if (props.screenSize !== "portrait" && props.screenSize !== "tablet")
      return <></>;
    if (props.screenSize === "portrait")
      return (
        <ButtonArray buttonSettings={navPageSettings} direction={"column"} />
      );
    if (props.screenSize === "tablet")
      return <ButtonArray buttonSettings={navPageSettings} />;
  };

  const getAdmin = () => {
    if (props.screenSize !== "desktop" && props.screenSize !== "laptop")
      return <></>;
    return <AdminModal submit={props.passwordSubmit} isAdmin={props.isAdmin}/>;
  };

  return (
    <Stack direction="column" height="100vh">
      <Stack direction={"row"} justifyContent={"center"}>
        <Box
          component="img"
          src={reflections}
          style={{
            backgroundImage: `url(${reflections})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            maxWidth: "100vw",
            maxHeight: "100vh",
          }}
        />
        <Stack display={"flex"} alignItems={"center"} sx={{position: "fixed"}}>
          <NavBarBuffer height={175}/>
          <StyledTypography color={"warning.main"}>
            Let me hear of your unfailing Love every morning
          </StyledTypography>
          <StyledTypography color={"warning.main"}>
            for I am trusting You.
          </StyledTypography>
          <StyledTypography color={"warning.main"}>
            Show me where to walk
          </StyledTypography>
          <StyledTypography color={"warning.main"}>
            for I give myself to You.
          </StyledTypography>
          <StyledTypography color={"warning.main"}>
          </StyledTypography>
          <StyledTypography color={"warning.main"}>
            Psalm 143:8
          </StyledTypography>
        </Stack>
          
      </Stack>
      {getButtons()}
      {getAdmin()}
    </Stack>
  );
};

export default Home;
