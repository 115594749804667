import { Box, Card, Stack } from "@mui/material";
import StyledTypography from "./StyledTypography";
import FileUploadedImage from "../forms/file/FileUploadedImage";
import LabeledTypography from "./LabeledTypography";
import NewTabLink from "../nav/NewTabLink";

const EventsCard = (props) => {
  return (
    <Box width={"fit-content"}>
      <Card>
        <Stack p={1}>
          <StyledTypography variant="h2">
            {props.content.title}
          </StyledTypography>
          <StyledTypography variant={"h5"} color={"warning.main"}>
            {props.content.subtitle}
          </StyledTypography>
          <Stack direction={"row"} mt={2} spacing={1}>
            <Stack>
              <FileUploadedImage maxHeight={700} image={props.content.image} />
              <NewTabLink
                href={props.content.link}
                text={props.content.linkText}
              />
            </Stack>
            <Stack>
              {props.content.dates && (
                <LabeledTypography color="warning.main" label="Dates:">
                  {props.content.dates}
                </LabeledTypography>
              )}
              {props.content.location && (
                <LabeledTypography color="warning.main" label="Location:">
                  {props.content.location}
                </LabeledTypography>
              )}
              <StyledTypography>{props.content.description}</StyledTypography>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default EventsCard;
