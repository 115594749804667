export const contentSchema = {
    gallery:{
        section:"gallery",
        entries:[
            {label:"title",entryType:"text",default:"New Gallery Item",multiline:false,type:null},
            {label:"scripture",entryType:"text",default:"",multiline:false,type:null},
            {label:"media",entryType:"text",default:"Acrylic",multiline:false,type:null},
            {label:"surface",entryType:"text",default:"Canvas",multiline:false,type:null},
            {label:"dimensions",entryType:"text",default:"16in x 20in",multiline:false,type:null},
            {label:"creationDate",entryType:"text",default:"",multiline:false,type:null},
            {label:"inventory",entryType:"text",default:"",multiline:false,type:null},
            {label:"price",entryType:"text",default:"",multiline:false,type:null},
            {label:"comments",entryType:"text",default:"",multiline:true,type:null},
            {label:"posted",entryType:"text",default:"",multiline:false,type:"date"},
            {label:"collection",entryType:"text",default:"",multiline:true,type:null},
            {label:"url",entryType:"text",default:[],multiline:false,type:null},
            {label:"imageName",entryType:null,default:"",multiline:false,type:null},
            {label:"image",entryType:"image",default:null},
            {label:"archived",entryType:"checkBox",default:false}
        ]
    },
    collections:{
        section:"collections",
        entries:[
            {label:"title",entryType:"text",default:"New Collection Item",multiline:false,type:null}
        ]
    },
    events:{
        section:"events",
        entries:[
            {label:"title",entryType:"text",default:"New Events Item",multiline:false,type:null},
            {label:"subtitle",entryType:"text",default:"",multiline:false,type:null},
            {label:"dates",entryType:"text",default:"",multiline:false,type:null},
            {label:"location",entryType:"text",default:"",multiline:false,type:null},
            {label:"description",entryType:"text",default:"",multiline:true,type:null},
            {label:"link",entryType:"text",default:"",multiline:false,type:null},
            {label:"linkText",entryType:"text",default:"",multiline:false,type:null},
            {label:"posted",entryType:"text",default:"",multiline:false,type:"date"},
            {label:"expires",entryType:"text",default:"",multiline:false,type:"date"},
            {label:"url",entryType:"text",default:[],multiline:false,type:null},
            {label:"image",entryType:"image",default:null},
            {label:"archived",entryType:"checkBox",default:false}
        ]
    },
    about:{
        section:"about",
        entries:[
            {label:"title",entryType:"text",default:"New About Item",multiline:false,type:null},
            {label:"text",entryType:"text",default:"",multiline:true,type:null},
            {label:"image",entryType:"image",default:null},
            {label:"archived",entryType:"checkBox",default:false}
        ]
    },
    blog:{
        section:"blog",
        entries:[
            {label:"title",entryType:"text",default:"New Blog Item",multiline:false,type:null},
            {label:"subtitle",entryType:"text",default:"",multiline:false,type:null},
            {label:"date",entryType:"text",default:"",multiline:false,type:null},
            {label:"article",entryType:"text",default:"",multiline:true,type:null},
            {label:"posted",entryType:"text",default:"",multiline:false,type:"date"},
            {label:"expires",entryType:"text",default:"",multiline:false,type:"date"},
            {label:"url",entryType:"text",default:[],multiline:false,type:null},
            {label:"image",entryType:"image",default:null},
            {label:"archived",entryType:"checkBox",default:false}
        ]
    }
}