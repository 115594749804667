import { Stack } from "@mui/material";
import AboutCard from "../components/display/AboutCard";
import NavBarBuffer from "../components/nav/NavBarBuffer";
import StyledTypography from "../components/display/StyledTypography";

function About(props) {
  return (
    <Stack p={5} display={"flex"} alignItems={"center"} spacing={1}>
      <NavBarBuffer height={120} />
      <StyledTypography color="warning.main" variant={"h2"}>
        About the Artist
      </StyledTypography>
      {props.content &&
        props.content
          .filter((item) => !item.archived)
          .map((item, index) => (
            <AboutCard
              key={Math.random()}
              content={item}
              inverted={index % 2 === 0}
            />
          ))}
    </Stack>
  );
}

export default About;
