import { Stack } from "@mui/material";
import BlogCard from "../../components/display/BlogCard";
import NavBarBuffer from "../../components/nav/NavBarBuffer";
import StyledTypography from "../../components/display/StyledTypography";
import NewBadge from "../../components/display/NewBadge";

function Blog(props) {
  return (
    <Stack p={5} display={"flex"} alignItems={"center"} spacing={1}>
      <NavBarBuffer height={120} />
      <StyledTypography color="warning.main" variant={"h2"}>
        Artist's Musings
      </StyledTypography>
      {props.content
        .filter((item) => !item.archived && Date.now() < Date(item.expires))
        .map((item) => (
          <Stack key={Math.random()}>
            <NewBadge postedDate={item.posted} />
            <BlogCard content={item} />
          </Stack>
        ))}
    </Stack>
  );
}

export default Blog;
