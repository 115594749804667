import { Box, Stack, Button } from "@mui/material"

const ContentGroupControls = (props) => {

    const handleSubmit = () => {
        const submittedObj = { ...props.contentObj };
        delete submittedObj.temp;
        props.onSubmit(props.section, submittedObj, submittedObj.index);
        props.expandNamedArticle(false)
      };
    
      const handleDelete = () => {
        props.onSubmit(props.section, props.contentObj, props.contentObj.index, "delete");
      };
    
      const handleMoveUp = () => {
        props.onSubmit(props.section, props.contentObj, props.contentObj.index, "up");
      };
    
      const handleMoveDown = () => {
        props.onSubmit(props.section, props.contentObj, props.contentObj.index, "down");
      };
    
      const handleCancel = () => {
        props.onSubmit(props.section, props.contentObj, props.contentObj.index, "cancel");
      };
    return (
        <Stack direction={"row"}>
        <Box>
          <Button variant="contained" onClick={handleSubmit}>Submit</Button>
        </Box>
        {props.contentObj.temp &&
        <Box>
          <Button onClick={handleCancel}>Cancel</Button>
        </Box>}
        {!props.contentObj.temp && (
          <>
            <Box>
              <Button onClick={handleDelete}>Delete</Button>
            </Box>
            <Box>
              <Button onClick={handleMoveUp}>Move Up</Button>
            </Box>
            <Box>
              <Button onClick={handleMoveDown}>Move Down</Button>
            </Box>
          </>
        )}
      </Stack>
    )
}

export default ContentGroupControls