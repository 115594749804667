import { Badge, Typography } from "@mui/material"

const NewBadge = (props) => {
    const DAYS_OF_NEW_BADGE = 14;
    const MS_IN_A_DAY = 8.64e7;
    const isNew = (postedDateStr) => {
        const postedDate = new Date(postedDateStr);
        const numberOfDaysPassed = Math.ceil((Date.now() - postedDate) / MS_IN_A_DAY);
        return numberOfDaysPassed <= DAYS_OF_NEW_BADGE;
    }
    return (
        <Badge
          invisible={!props.postedDate || !isNew(props.postedDate)}
          badgeContent={<Typography variant="h5">New!</Typography>}
          color="primary"
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{transform: `translate(${props.xOffset || 0}px, ${props.yOffset || 0}px)`}}
        />
    )
}

export default NewBadge