import { Button, Stack, Typography } from "@mui/material";
import NavBarBuffer from "../../components/nav/NavBarBuffer";
import GalleryCarousel from "../../components/display/GalleryCarousel";
import GalleryCard from "../../components/display/GalleryCard";
import NewBadge from "../../components/display/NewBadge";
import { useState } from "react";

function Gallery(props) {
  const [currentCollection, setCurrentCollection] = useState("All");

  const buttonCollections = [{ title: "All" }, ...props.collections];

  return (
    <Stack>
      <NavBarBuffer height={160} />
      {props.content.length === 0 && <Typography>No Gallery Items</Typography>}
      {props.content.length > 0 && (
        <Stack p={1} spacing={2} display={"flex"} alignItems={"center"}>
          <Stack direction={"row"} spacing={2}>
            {buttonCollections.map((collection) => (
              <Button
                key={collection.title}
                variant="text"
                onClick={() => {
                  setCurrentCollection(collection.title);
                }}
                sx={{ textTransform: "none" }}
              >
                <Typography
                  variant="h5"
                  color={
                    collection.title === currentCollection
                      ? "text.primary"
                      : "primary.main"
                  }
                >
                  {collection.title}
                </Typography>
              </Button>
            ))}
          </Stack>
          <GalleryCarousel
            height={700}
            content={props.content.filter(
              (item) =>
                (currentCollection === "All" ||
                  item.collection === currentCollection) &&
                !item.archived
            )}
          />
          {props.content
            .filter(
              (item) =>
                (currentCollection === "All" ||
                  item.collection === currentCollection) &&
                !item.archived
            )
            .map((item) => (
              <Stack key={Math.random()}>
                <NewBadge postedDate={item.posted} />
                <GalleryCard key={Math.random()} content={item} />
              </Stack>
            ))}
        </Stack>
      )}
    </Stack>
  );
}

export default Gallery;
