import { Button, Typography } from "@mui/material"

const EmailButton = (props) => {
    return (
        <Button
            variant="contained"
            color="primary"
            target="_top"
            rel="noopener noreferrer"
            href={`mailto:${props.email}`}
          >
            <Typography>{props.text}</Typography>
          </Button>
    )
}

export default EmailButton