import { Card, Divider, Stack, Box } from "@mui/material";
import FileUploadedImage from "../forms/file/FileUploadedImage";
import StyledTypography from "./StyledTypography";
import LabeledTypography from "./LabeledTypography";

const GalleryCard = (props) => {
  return (
    <Box width={"fit-content"}>
      <Card>
        <Stack direction={"row"} p={1}>
          <Stack display={"flex"} alignItems={"center"}>
            <FileUploadedImage maxHeight={700} image={props.content.image} />
            <StyledTypography variant={"h2"}>
              {props.content.title}
            </StyledTypography>
            <Stack
              direction={"row"}
              display={"flex"}
              justifyContent={"space-between"}
              mt={4}
              spacing={10}
              width={"100%"}
            >
              <StyledTypography color="warning.main">
                {props.content.scripture}
              </StyledTypography>
              <Stack>
                {props.content.media && (
                  <LabeledTypography color="warning.main" label="Media Type:">
                    {props.content.media}
                  </LabeledTypography>
                )}

                {props.content.surface && (
                  <LabeledTypography color="warning.main" label="Surface Type:">
                    {props.content.surface}
                  </LabeledTypography>
                )}

                {props.content.dimensions && (
                  <LabeledTypography color="warning.main" label="Dimensions:">
                    {props.content.dimensions}
                  </LabeledTypography>
                )}

                {props.content.creationDate && (
                  <LabeledTypography
                    color="warning.main"
                    label="Creation Date:"
                  >
                    {props.content.creationDate}
                  </LabeledTypography>
                )}

                {props.content.inventory && (
                  <LabeledTypography color="warning.main" label="Inventory #:">
                    {props.content.inventory}
                  </LabeledTypography>
                )}

                {props.content.price && (
                  <LabeledTypography color="warning.main" label="Price:">
                    {props.content.price}
                  </LabeledTypography>
                )}

                {props.content.comments && <Divider />}
                {props.content.comments && (
                  <LabeledTypography
                    color="warning.main"
                    label={"Artist's\nMusings:"}
                  >
                    {props.content.comments}
                  </LabeledTypography>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Card>
    </Box>
  );
};

export default GalleryCard;
