import { createTheme } from "@mui/material"
const teal = "#2B7C85";
const tealGreen = "#175873";
const royalBlue = "#0C1446";
const seafoamGreen = "#87ACA3";
export default createTheme({
    palette:{
        primary:{main: teal},
        secondary:{main: tealGreen},
        warning:{main: royalBlue},
        info:{main: seafoamGreen},
        text:{
            primary: "#ffffff", 
            secondary: seafoamGreen
        },
        background: {
            paper: "#ffffff",
            default: seafoamGreen
        }
    },
    typography: {
        fontFamily:["Alkatra","cursive"].join(","),
        h1:{fontFamily:["Dancing Script","cursive"].join(",")},
        h2:{fontFamily:["Dancing Script","cursive"].join(",")},
        h3:{fontFamily:["Dancing Script","cursive"].join(",")},
        h4:{fontFamily:["Dancing Script","cursive"].join(",")}
    },
    breakpoints: {
        keys: [
            "portrait",
            "landscape",
            "tablet",
            "laptop",
            "desktop"
        ],
        values: {
          portrait: 0,
          landscape: 600,
          tablet: 900,
          laptop: 1200,
          desktop: 1536,
        },
      }
  });