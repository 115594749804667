import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import FormAccordion from '../FormAccordion';
import { useState } from 'react';
const DateInput = (props) => {

    const [open,setOpen] = useState(false)

    const handleChange = (isExpanded) => {
        setOpen(isExpanded)
    }

    const handleCalendarChange = (value) => {
        props.onChange(value)
        setOpen(false)
    }
    return (
        <FormAccordion open={open} title={props.value} default={props.default} handleChange={handleChange}>
            <DateCalendar onChange={handleCalendarChange} sx={{background:"#2B7C85"}}/>
        </FormAccordion>
    )
}

export default DateInput