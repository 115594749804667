import { Stack, Typography, Divider, Button } from "@mui/material";
import NavBarBuffer from "../components/nav/NavBarBuffer";
import FormAccordian from "../components/forms/FormAccordion";
import OneTextForm from "../components/forms/text/OneTextForm";
import { useState } from "react";
import ContentGroup from "../components/forms/content/ContentGroup";
import { contentSchema } from "../consts/contentSchema";

function Admin(props) {

  const [expanded, setExpanded] = useState(false);

  const handleChange = (isExpanded, title) => {
    setExpanded(() => (isExpanded ? title : false));
  };

  const updatePassword = (password) => {
    props.onNewPassword(password);
  };

  const updateContactEmail = (email) => {
    const newContent = { ...props.content };
    newContent.contact = email;
    props.setContent(() => newContent);
  };

  const updateAuthTimeout = (timeout) => {
    props.onNewAuthTimeout(Math.max(parseInt(timeout),10) * 60 * 1000)
  }

  const updateWholeSection = (section, content) => {
    const newContent = { ...props.content };
    newContent[section] = content;
    props.setContent(() => newContent);
  };

  const updateSectionItem = (section, content, index) => {
    const newContent = { ...props.content };
    newContent[section][index] = content;
    props.setContent(() => newContent);
  };

  const deleteSectionItem = (section, index) => {
    const newContent = { ...props.content };
    newContent[section] = newContent[section].filter(
      (item) => item.index !== index
    );
    props.setContent(() => newContent);
  };

  const moveItemInList = (section, index, delta) => {
    if (index + delta < 0 || index + delta >= props.content[section].length)
      return;
    const newContent = { ...props.content };
    const tempData = newContent[section][index];
    newContent[section][index] = newContent[section][index + delta];
    newContent[section][index + delta] = tempData;
    newContent[section].forEach((item, i) => (item.index = i));
    props.setContent(() => newContent);
  };

  const deleteTempItems = (section) => {
    const newContent = { ...props.content };
    newContent[section] = newContent[section].filter((item) => !item.temp);
    props.setContent(() => newContent);
  };

  const updateSectionContent = (
    section,
    content,
    index = null,
    moveItem = false
  ) => {
    if (moveItem === "delete") {
      deleteSectionItem(section, index);
    } else if (moveItem === "up") {
      moveItemInList(section, index, -1);
    } else if (moveItem === "down") {
      moveItemInList(section, index, 1);
    } else if (moveItem === "cancel") {
      deleteTempItems(section);
    } else if (index !== null) {
      updateSectionItem(section, content, index);
    } else {
      updateWholeSection(section, content);
    }
  };

  const closeAll = () => {
    setExpanded(false);
  };

  const expandNamedArticle = (name) => {
    setExpanded(name);
  };
  
  return (
    <Stack p={5} spacing={1}>
      <NavBarBuffer />
      <Typography variant="h5">Admin Page</Typography>
      <Typography variant="h6">Content</Typography>
      <Button variant="contained" onClick={props.onPublish}><Typography>Publish</Typography></Button>
      <ContentGroup
        expandNamedArticle={expandNamedArticle}
        handleChange={handleChange}
        expanded={expanded}
        content={props.content.gallery}
        schema={contentSchema.gallery}
        onSubmit={updateSectionContent}
      />
      <Divider color="#ffffff" />
      <ContentGroup
        expandNamedArticle={expandNamedArticle}
        handleChange={handleChange}
        expanded={expanded}
        content={props.content.collections}
        schema={contentSchema.collections}
        onSubmit={updateSectionContent}
      />
      <Divider color="#ffffff" />
      <ContentGroup
        expandNamedArticle={expandNamedArticle}
        handleChange={handleChange}
        expanded={expanded}
        content={props.content.events}
        schema={contentSchema.events}
        onSubmit={updateSectionContent}
      />
      <Divider color="#ffffff" />
      <ContentGroup
        expandNamedArticle={expandNamedArticle}
        handleChange={handleChange}
        expanded={expanded}
        content={props.content.blog}
        schema={contentSchema.blog}
        onSubmit={updateSectionContent}
      />
      <Divider color="#ffffff" />
      <ContentGroup
        expandNamedArticle={expandNamedArticle}
        handleChange={handleChange}
        expanded={expanded}
        content={props.content.about}
        schema={contentSchema.about}
        onSubmit={updateSectionContent}
      />
      <FormAccordian
        title={`Update Contact Email: ${props.content.contact}`}
        expanded={expanded}
        handleChange={handleChange}
      >
        <OneTextForm updateText={updateContactEmail} close={closeAll} />
      </FormAccordian>
      <Typography variant="h6">Admin Functions</Typography>
      <FormAccordian
        title="Update Admin Password"
        expanded={expanded}
        handleChange={handleChange}
      >
        <OneTextForm updateText={updatePassword} close={closeAll} />
      </FormAccordian>
      <FormAccordian
        title={`Set Authentication Timeout (in minutes)`}
        expanded={expanded}
        handleChange={handleChange}
      >
        <OneTextForm updateText={updateAuthTimeout} close={closeAll} type={"number"}/>
      </FormAccordian>
    </Stack>
  );
}

export default Admin;
