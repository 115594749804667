import { Checkbox } from "@mui/material"

const CheckBox = (props) => {

    const checkBoxEventChangeHandler = (e) => {
        props.onChange(e.target.checked)
    }
    return (
        <Checkbox checked={props.checked} onChange={checkBoxEventChangeHandler}/>
    )
}

export default CheckBox