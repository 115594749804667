import { Typography } from "@mui/material";

const StyledTypography = (props) => {
  return (
    <Typography
      color={props.color || "primary"}
      variant={props.variant}
      component="pre"
    >
      {props.children}
    </Typography>
  );
};

export default StyledTypography
