import { TextField } from "@mui/material";

const StyledTextField = (props) => {
  return (
    <TextField
      value={props.value || ""}
      type={props.type}
      onChange={(e) => {
        props.setValue(e.target.value);
      }}
      multiline={props.multiline}
      minRows={3}
      sx={{ backgroundColor: "#2B7C85", width: "100%" }}
      autoFocus={props.autofocus}
      inputProps={props.pattern || {}}
    />
  );
};

export default StyledTextField;
