import Carousel from "react-material-ui-carousel";
import GalleryItemButton from "../nav/GalleryItemButton";
import { Box, Card, Stack } from "@mui/material";

const GalleryCarousel = (props) => {
  return (
    <Stack display={"flex"} alignItems={"center"}>
      <Box width={"75vw"} sx={{ overflow: "hidden" }}>
        <Card>
          <Carousel
            animation="slide"
            interval={4500}
            duration={500}
            height={props.height}
            navButtonsAlwaysVisible={true}
          >
            {props.content.map((item) => (
              <Stack key={Math.random()} height={"100%"} display={"flex"} justifyContent={"center"}>
                <GalleryItemButton
                  image={item.image}
                  url={item.url}
                />
              </Stack>
            ))}
          </Carousel>
        </Card>
      </Box>
    </Stack>
  );
};

export default GalleryCarousel;
