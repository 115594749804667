import { Accordion, AccordionSummary, AccordionDetails, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const FormAccordion = (props) => {

    const onChange = (e, isExpanded) => {
        props.handleChange(isExpanded, props.title)
    }
    
    return (
        <Accordion
            expanded={props.open || props.expanded === props.title}
            onChange={onChange}
        >
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                <Typography color="primary">
                    {props.title ? props.title : props.default}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                {props.children}
            </AccordionDetails>
        </Accordion>
    )
}

export default FormAccordion