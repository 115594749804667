import { Stack } from "@mui/material";
import { useState } from "react";
import LabeledTextField from "../text/LabeledTextField";
import ContentGroupControls from "./ContentGroupControls";

const CollectionsContentForm = (props) => {
  const [collectionsObj, setCollectionsObj] = useState(props.collectionsObj);

  const handleChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...collectionsObj };
      newObj[key] = value;
      setCollectionsObj(() => newObj);
    };
  };

  return (
    <Stack spacing={1}>
      <LabeledTextField
        label="Title:"
        value={collectionsObj.title}
        setValue={handleChangeFactory("title")}
        autofocus={true}
      />
      <ContentGroupControls
        expandNamedArticle={props.expandNamedArticle}
        onSubmit={props.onSubmit}
        section={"collections"}
        contentObj={collectionsObj}
      />
    </Stack>
  );
};

export default CollectionsContentForm;