import { Button, Stack } from "@mui/material";
import { useState } from "react";
import StyledTextField from "./StyledTextField";

const OneTextForm = (props) => {
  const [text, setText] = useState("");
  return (
    <Stack direction={"row"} spacing={3}>
      <StyledTextField value={text} setValue={setText} type={props.type}/>
      <Button
        variant="contained"
        onClick={() => {
          props.updateText(text);
          props.close();
        }}
      >
        Submit
      </Button>
    </Stack>
  );
};

export default OneTextForm;
