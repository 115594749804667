import { Stack, Button, Typography } from "@mui/material";
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BookIcon from '@mui/icons-material/Book';
import RecordVoiceOverIcon from '@mui/icons-material/RecordVoiceOver';
import DateRangeIcon from '@mui/icons-material/DateRange';
import PetsIcon from '@mui/icons-material/Pets';
const ButtonArray = (props) => {

  const getButtonIcon = (page, color) => {
    if (page === "Gallery") return <ColorLensIcon sx={{color:color}} />;
    if (page === "About") return <PetsIcon sx={{color:color}} />;
    if (page === "Contact") return <RecordVoiceOverIcon sx={{color:color}} />;
    if (page === "Events") return <DateRangeIcon sx={{color:color}} />;
    if (page === "Blog") return <BookIcon sx={{color:color}} />;
    return null;
  }

  const buttonWidth = props.width || "120px"
  return (
    <Stack
      direction={props.direction || "row"}
      justifyContent={props.justifyContent || "space-evenly"}
      alignItems={props.alignItems || "center"}
      spacing={props.spacing || 2}
      sx={{ flexGrow: 1}}
    >
      {props.buttonSettings.map((set) => {
        return (
          <Button
            key={set.page}
            startIcon={set.type !== "text" && getButtonIcon(set.page, set.color)}
            variant={set.type}
            onClick={set.onClick}
            sx={{width:buttonWidth, borderColor:set.color}}
          >
            <Typography color={set.color || "primary.main"}>
              {set.page}
            </Typography>
          </Button>
        );
      })}
    </Stack>
  );
};

export default ButtonArray;
