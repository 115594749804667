import FileUploadedImage from "../forms/file/FileUploadedImage"
import { useNavigate } from "react-router-dom"
import { Stack } from "@mui/material"
const GalleryItemButton = (props) => {

    const navigate = useNavigate()
    const handleClick = () => {
        props.url[0]!==undefined && navigate(`/gallery/${props.url[0]}`)
    }
    return (
        <Stack display={"flex"} alignItems={"center"}>
            <FileUploadedImage image={props.image} onClick={handleClick} maxHeight={700}/>
        </Stack>
    )
}

export default GalleryItemButton