import { Stack } from "@mui/material";
import { useState } from "react";
import LabeledTextField from "../text/LabeledTextField";
import LabeledDateInput from "../date/LabeledDateInput";
import LabeledCheckBox from "../checkbox/LabeledCheckBox";
import ImageSourceGroup from "../file/ImageSourceGroup"
import ContentGroupControls from "./ContentGroupControls";

const GalleryContentForm = (props) => {
  const [galleryObj, setGalleryObj] = useState(props.galleryObj);

  const handleUrlChange = (value) => {
    const newObj = { ...galleryObj };
    newObj.url = value.split(" ");
    setGalleryObj(()=>newObj)
  }

  const handleChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...galleryObj };
      newObj[key] = value;
      setGalleryObj(() => newObj);
    };
  };

  const handleDateChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...galleryObj };
      newObj[key] = `${value.$M+1}-${value.$D}-${value.$y}`
      setGalleryObj(()=>newObj)
    };
  }

  return (
    <Stack spacing={1}>
      <LabeledTextField
        label="Title:"
        value={galleryObj.title}
        setValue={handleChangeFactory("title")}
        autofocus={true}
      />
      <LabeledTextField
        label="Scripture:"
        value={galleryObj.scripture}
        setValue={handleChangeFactory("scripture")}
        multiline
      />
      <LabeledTextField
        label="Media:"
        value={galleryObj.media}
        setValue={handleChangeFactory("media")}
      />
      <LabeledTextField
        label="Surface:"
        value={galleryObj.surface}
        setValue={handleChangeFactory("surface")}
      />
      <LabeledTextField
        label="Dimensions:"
        value={galleryObj.dimensions}
        setValue={handleChangeFactory("dimensions")}
      />
      <LabeledTextField
        label="Creation Date:"
        value={galleryObj.creationDate}
        setValue={handleChangeFactory("creationDate")}
      />
      <LabeledTextField
        label="Inventory:"
        value={galleryObj.inventory}
        setValue={handleChangeFactory("inventory")}
      />
      <LabeledTextField
        label="Price:"
        value={galleryObj.price}
        setValue={handleChangeFactory("price")}
      />
      <LabeledTextField
        label="Comments:"
        value={galleryObj.comments}
        setValue={handleChangeFactory("comments")}
        multiline
      />
      <LabeledDateInput
        label="Posted:"
        value={galleryObj.posted}
        default={"Select a Posted Date"}
        onChange={handleDateChangeFactory("posted")}
      />
      <LabeledTextField
        label="Collection:"
        value={galleryObj.collection}
        setValue={handleChangeFactory("collection")}
      />
      <LabeledTextField
        label="Url:"
        value={galleryObj.url.join(" ")}
        setValue={handleUrlChange}
      />
      <ImageSourceGroup
        label="Image Source:"
        value={galleryObj.image}
        setValue={handleChangeFactory("image")}
      />
      <LabeledCheckBox
        label="Archived:"
        checked={galleryObj.archived}
        onChange={handleChangeFactory("archived")}
      />
      <ContentGroupControls
        expandNamedArticle={props.expandNamedArticle}
        onSubmit={props.onSubmit}
        section={"gallery"}
        contentObj={galleryObj}
      />
    </Stack>
  );
};

export default GalleryContentForm;
