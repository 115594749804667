import { Stack } from "@mui/material";
import { useState } from "react";
import LabeledTextField from "../text/LabeledTextField";
import ImageSourceGroup from "../file/ImageSourceGroup";
import ContentGroupControls from "./ContentGroupControls";
import LabeledCheckBox from "../checkbox/LabeledCheckBox";

const AboutContentForm = (props) => {
  const [aboutObj, setAboutObj] = useState(props.aboutObj);

  const handleChangeFactory = (key) => {
    return (value) => {
      const newObj = { ...aboutObj };
      newObj[key] = value;
      setAboutObj(() => newObj);
    };
  };

  return (
    <Stack spacing={1}>
      <LabeledTextField
        label="Title:"
        value={aboutObj.title}
        setValue={handleChangeFactory("title")}
        autofocus={true}
      />
      <LabeledTextField
        label="Text:"
        value={aboutObj.text}
        setValue={handleChangeFactory("text")}
        multiline
      />
      <ImageSourceGroup
        label="Image Source:"
        value={aboutObj.image}
        setValue={handleChangeFactory("image")}
      />
      <LabeledCheckBox
        label="Archived:"
        checked={aboutObj.archived}
        onChange={handleChangeFactory("archived")}
      />
      <ContentGroupControls
        expandNamedArticle={props.expandNamedArticle}
        onSubmit={props.onSubmit}
        section={"about"}
        contentObj={aboutObj}
      />
    </Stack>
  );
};

export default AboutContentForm;
