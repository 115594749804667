import { Box, Card, Stack } from "@mui/material";
import StyledTypography from "../components/display/StyledTypography";
import EmailButton from "../components/nav/EmailButton";
import NavBarBuffer from "../components/nav/NavBarBuffer";
import NewTabLink from "../components/nav/NewTabLink";

function Contact(props) {
  return (
    <Stack display={"flex"} alignItems={"center"} spacing={2}>
      <NavBarBuffer />
      <Stack p={1} spacing={1} display={"flex"} alignItems={"center"}>
        <Box width="fit-content">
          <Card>
            <Stack p={1} spacing={2} display={"flex"} alignItems={"center"}>
              <StyledTypography variant={"h2"}>
                Feel free to contact me here:
              </StyledTypography>
              <StyledTypography variant={"h5"}>
                {props.contact}
              </StyledTypography>
              <EmailButton email={props.contact} text="Click me to open Outlook"/>
              <StyledTypography>
                (or check out my Events page and meet me in person!)
              </StyledTypography>
            </Stack>
          </Card>
        </Box>
        <Box width="fit-content">
          <Card>
            <Stack p={1}display={"flex"} alignItems={"center"}>
              <StyledTypography>
                Website designed by:
              </StyledTypography>
              <NewTabLink text={"Rob Lewis"} href="https://www.robsim37.com"/>
            </Stack>
          </Card>
        </Box>
      </Stack>
    </Stack>
  );
}

export default Contact;
